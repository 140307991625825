import styled from 'styled-components'

export default styled.div`
  width: 90%;
  margin: 0 auto;
  
  @media (min-width: 30em) {
    max-width: 1024px;
    width: 80%;
  }
`
