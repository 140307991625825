import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import filter from 'lodash/filter'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Container from '../components/Container'
import Link from '../components/Link'

import colors from '../assets/js/colors'
import logo from '../assets/img/logo.png'

const Header = styled.h1`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
`

const Subheader = styled.h1`
  display: flex;
  align-items: center;
  color: gray;
  font-size: 1.2rem;
`

const Logo = styled.img`
  width: 48px;
  margin-right: .5rem;
`

const Excerpt = styled.p`
  color: ${colors.text};
  font-size: .9rem;
  margin-bottom: 2rem;
`

const List = ({ title, list }) =>
  <React.Fragment>
    <h2>{title}</h2>
    {list.map(({ node }) =>
      <div key={node.fields.slug}>
        <h4><Link underline to={node.fields.slug}>{get(node, 'frontmatter.title') || node.fields.slug}</Link></h4>
        <Excerpt dangerouslySetInnerHTML={{ __html: node.excerpt }} />
      </div>
    )}
  </React.Fragment>

const EmailForm = ({ data }) => {
  const title = get(data, 'site.siteMetadata.title')
  const pages = get(data, 'allMarkdownRemark.edges')
  const english = filter(pages, page => page.node.fields.slug.includes('/en/'))
  const spanish = filter(pages, page => page.node.fields.slug.includes('/es/'))

  return (
    <Container>

      <Helmet>
        <title>{title}</title>
        <meta property='description' content='Dot Dot Data | Law Offices of Ezequiel Reyna, Jr.' />
        <link rel='shortcut icon' href='favicon.ico' />
      </Helmet>

      <Header><Logo src={logo} alt='logo' /><span>Dot Dot Data Pages</span></Header>
      <Subheader>Law Offices of Ezequiel Reyna, Jr.</Subheader>

      <List title='English' list={english} />
      <List title='Spanish' list={spanish} />

    </Container>
  )
}

export default EmailForm

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
